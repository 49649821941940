import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { ReactComponent as Logo } from '../../assets/svg/Vector.svg';

import { useAuth } from 'context/authContext';
import { DOCUMENTS_URL } from 'env';
import { setUserErrorsAction } from 'slices/authSlice';
import { PageContainer, PaperContainer, TextLink } from 'styles/ReusabledStyledComponents';

const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
`;

const Form = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { login, error } = useAuth();
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (error) {
            setShowErrorMsg(true);
        }
    }, [error]);

    const onSubmit = (data: { email: string; password: string }) => {
        const trimmedData = {
            email: data.email.trim(),
            password: data.password.trim(),
        };
        login?.(trimmedData.email, trimmedData.password);
    };

    const closeModal = () => {
        dispatch(setUserErrorsAction(null));
        setShowErrorMsg(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <>
            <Helmet>
                <title>{t('LoginPage.helmetTitle')}</title>
            </Helmet>
            <PageContainer data-testid="login_page">
                <Container>
                    <PaperContainer square={true}>
                        <Logo height={100} width={'auto'} />
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="normal"
                                    label={t('LoginPage.emailLabel')}
                                    error={errors.email !== undefined}
                                    helperText={errors.email !== undefined && t('Global.required')}
                                    {...register('email', { required: true })}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    margin="normal"
                                    label={t('LoginPage.passwordLabel')}
                                    error={errors.password !== undefined}
                                    helperText={
                                        errors.password !== undefined && t('Global.required')
                                    }
                                    {...register('password', { required: true })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={togglePasswordVisibility}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{ marginTop: 32 }}
                            >
                                {t('LoginPage.submitButton')}
                            </Button>
                        </Form>
                        <TextLink
                            style={{ paddingTop: 25 }}
                            href={DOCUMENTS_URL.PRIVACY_POLICY_URL}
                        >
                            {t('LoginPage.privacyPolicy')}
                        </TextLink>
                    </PaperContainer>
                </Container>
            </PageContainer>

            <Dialog
                open={showErrorMsg}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error === 'InvalidCredentials'
                            ? t('LoginPage.invalidCredentials')
                            : t('LoginPage.noPermission')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        {t('Global.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LoginPage;
