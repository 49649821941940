export const API_ROUTES = {
    LOGIN: '/auth/login',
    USERS: '/users/',
    DATA_DUMP: '/dump/',
    USER_SEGMENT: '/user_segment/',
    USER_JOURNAL: '/journal_responses/',
    USER_PROFILE: `/users/:userId/user_profile`,
    VERIFY_CREATE_PASSWORD_TOKEN: '/auth/verify-create-password-token',
    RESET_PASSWORD: '/users/reset-password',
    SEND_CREATE_PASSWORD_EMAIL: '/users/:userId/send-create-password',
    STATS: '/stats',
};
