import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';

import { AppBar, Button, IconButton, Toolbar, Typography } from '@material-ui/core';
import Home from '@material-ui/icons/Home';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/svg/Vector.svg';

import { useUser } from 'context/userContext';
import { colours } from '../constants/colours';
import { logout } from 'slices/authSlice';
import { axiosInstance } from '../axiosInstance';
import { API_ROUTES } from '../constants/api';

const ToolBar = styled(Toolbar)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Header: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useUser();
    const history = useHistory();

    const logOut = () => {
        dispatch(logout());
    };

    const downloadDb = async () => {
        const response = await axiosInstance.get(API_ROUTES.DATA_DUMP, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'database_dump.sql');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const navigateToHome = () => {
        history.push(`/`);
    };
    return user ? (
        <AppBar position="static">
            <ToolBar>
                <IconButton
                    style={{ flex: 1, maxWidth: 100 }}
                    onClick={navigateToHome}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                >
                    <Logo style={{ flex: 1, maxWidth: 100 }} height={40} width={'auto'} />
                </IconButton>

                <div style={{ padding: 5 }}>
                    <Button
                        style={{
                            flex: 1,
                            maxWidth: 300,
                            marginRight: 30,
                            border: 'solid 1px white',
                        }}
                        onClick={downloadDb}
                        color="inherit"
                    >
                        {t('Header.dbDump')}
                    </Button>

                    <Button style={{ flex: 1, maxWidth: 100 }} onClick={logOut} color="inherit">
                        {t('Header.logout')}
                    </Button>
                </div>
            </ToolBar>
        </AppBar>
    ) : null;
};

export default Header;
